import { BrowserRouter } from "react-router-dom";

import { Hero, Navbar, Tech, Contacts, StarsCanvas } from "./components";

const App = () => {
  return (
    <BrowserRouter>
      <div className="relative z-0 bg-primary">
        <div className="bg-cover bg-no-repeat bg-center">
          <Navbar />
          <div className="relative z-0">
            <Hero />
            <Contacts />
            <Tech />
            <StarsCanvas />
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
/* <About />
 <Works />
 <Tech />
   
*/
