import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, Preload, useGLTF } from "@react-three/drei";
import CanvasLoader from "../Loader";



const Sun = ({ isMobile, urlGLTF, GLTFvalues, GLTFscale, GLTFvaluesMobile, GLTFscaleMobile }) => {
  const computer = useGLTF(urlGLTF);
    return (   
    <group>      
      <hemisphereLight intensity={2} groundColor='black' />
      <spotLight
        position={[0, 0, 0]}
        angle={0.130}
        penumbra={1}
        intensity={5}
        castShadow
        shadow-mapSize={1024}
      />
      <pointLight intensity={2} position={[10, 5, 0]} />
      <primitive
          
        object={computer.scene}
        scale={isMobile ? GLTFscaleMobile : GLTFscale}
        position={isMobile ? GLTFvaluesMobile : GLTFvalues}
        rotation-y={1} rotation-x={1}
        
      />


    </group>
  );
};

const Earth = ({ isMobile, urlGLTF, GLTFvalues, GLTFscale}) => {
  const earth = useGLTF(urlGLTF);
  const moon = useGLTF("./moon/scene.gltf");
  const primitiveRefEarth= useRef()
  const primitiveRefMoon= useRef(); // Create a ref for the Earth component
  const orbitRadius = 55;
  const orbitRadiusMoon = 50;
  const rotationSpeed = 0.05;
  const orbitSpeed = 3.2;
  useFrame((_, delta) => {
    if (primitiveRefEarth.current) {
      primitiveRefEarth.current.rotation.y += delta * rotationSpeed; 
      // Modify this value to change the rotation speed
      const angle = primitiveRefEarth.current.rotation.y * orbitSpeed;
      const x = Math.cos(angle) * orbitRadius;
      const z = Math.sin(angle) * orbitRadius;      
      
      primitiveRefEarth.current.position.set(x, primitiveRefEarth.current.position.y, z);  
      
      if (primitiveRefMoon.current) {
        primitiveRefMoon.current.rotation.y += delta * rotationSpeed;
        const angleMoon = primitiveRefEarth.current.rotation.y * 8;
        const xMoon = Math.cos(angleMoon) * orbitRadiusMoon * 0.3 + x;
        const zMoon = Math.sin(angleMoon) * orbitRadiusMoon * 0.3 + z;
        primitiveRefMoon.current.position.set(xMoon, primitiveRefMoon.current.position.y, zMoon);
      }
    }
  });
  return (
  
    <group>      
      <hemisphereLight intensity={1} groundColor='black' />
      <spotLight
        position={[0, 0, 0]}
        angle={0.0}
        penumbra={1}
        intensity={1}
        castShadow
        shadow-mapSize={1024}
      />
      <pointLight intensity={8}   
        angle={0.50}/>
      <primitive
        object={earth.scene}
        ref={primitiveRefEarth}       
        scale={isMobile ? 0.7 : GLTFscale}
        position={isMobile ? GLTFvalues : GLTFvalues}
        rotation-y={3} rotation-x={0}
        
      />
    <primitive 
      name="moon"
       object={moon.scene}   ref={primitiveRefMoon}  scale={isMobile ? 0.1 : 0.2}
    /> 

    </group>
  );
};

const ComputersCanvas = () => {
  const [isMobile, setIsMobile] = useState(false);
  
  

  useEffect(() => {
    // Add a listener for changes to the screen size
    const mediaQuery = window.matchMedia("(max-width: 500px)");

    // Set the initial value of the `isMobile` state variable
    setIsMobile(mediaQuery.matches);

    // Define a callback function to handle changes to the media query
    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    // Add the callback function as a listener for changes to the media query
    mediaQuery.addEventListener("change", handleMediaQueryChange, { passive: true });

    // Remove the listener when the component is unmounted
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);
  
  
  
  return (

    //isMobile ? "prova" :  
    
    <Canvas
    frameloop='always'
    shadows
   
    camera={{
      fov:35,
      near: 20,
      far: 180,
      position: [15, -15,100]
    }}
    
    
    gl={{ preserveDrawingBuffer: true }}
  >


    
   
      <OrbitControls
      
        enableZoom={false}
        autoRotate={false}

        autoRotateSpeed = {0.2}
        maxPolarAngle={Math.PI /10}
        minPolarAngle={Math.PI /2}
        
      />
     
     <Sun isMobile={isMobile} urlGLTF={"./sun/scene.gltf"} GLTFvalues={[35, 5, -10]} GLTFvaluesMobile={[0, 5, 10]} GLTFscale={2.40} GLTFscaleMobile={2.40}/>
      <Earth  isMobile={isMobile} urlGLTF={"./earth/scene.gltf"} GLTFvalues={[35, 3, -40]} GLTFvaluesMobile={[0, 3, 40]} GLTFscale={5.25} GLTFscaleMobile={5.25}/>
      
      
    
      

   
    
  
   

    <Preload all />
  </Canvas>
    
    
    
    
  );
};

export default ComputersCanvas;
